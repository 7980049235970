import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
import _mean from "lodash/mean";
import _max from "lodash/max";
import _min from "lodash/min";
import { deviation, median as _median } from "d3";
import useBaseUtils from "~/functions/useBaseUtils";
import useToFormatWatchlist from "~/functions/useToFormatWatchlist";
import useNumberFormatters from "~/functions/useNumberFormatters";
export default defineComponent({
  props: {
    results: {
      type: Object,
      "default": function _default() {
        return {
          rows: [],
          fields: []
        };
      }
    },
    // COLUMNS NOW ONLY CONTAINS DATA COLUMNS
    columns: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    // screenerId: {
    //   type: String,
    //   default: "testid",
    // },
    showTurns: {
      type: Boolean,
      "default": true
    },
    baseAggList: {
      type: Array,
      "default": function _default() {
        return [{
          name: "Mean"
        }, {
          name: "Median"
        }, {
          name: "High"
        }, {
          name: "Low"
        }, {
          name: "Standard Deviation"
        }, {
          name: "Count"
        }];
      }
    },
    dense: {
      type: Boolean,
      "default": false
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var _useToFormatWatchlist = useToFormatWatchlist(),
      displayRedWhenValNegative = _useToFormatWatchlist.displayRedWhenValNegative;
    var _useNumberFormatters = useNumberFormatters(),
      displayPercentTwoDecimals = _useNumberFormatters.displayPercentTwoDecimals,
      formatMarketCapValue = _useNumberFormatters.formatMarketCapValue,
      formatTurnsValue = _useNumberFormatters.formatTurnsValue,
      displayFloat = _useNumberFormatters.displayFloat,
      displayInt = _useNumberFormatters.displayInt;
    var testNull = function testNull(val) {
      if (isNaN(val)) {
        return true;
      }
      if (typeof val === "number" && val === 0) {
        return false;
      } else {
        return _typeof(val) === "object" && !val;
      }
    };
    return {
      displayRedWhenValNegative: displayRedWhenValNegative,
      displayPercentTwoDecimals: displayPercentTwoDecimals,
      formatMarketCapValue: formatMarketCapValue,
      formatTurnsValue: formatTurnsValue,
      displayFloat: displayFloat,
      displayInt: displayInt,
      testNull: testNull,
      I18nFn: I18nFn
    };
  },
  computed: {
    aggColumns: function aggColumns() {
      var firstAggCol = [{
        text: this.I18nFn("Summary Statistics"),
        value: "name",
        width: "140px",
        align: "center"
      }];
      var numberColumns = this.columns.map(function (m) {
        return _objectSpread(_objectSpread({}, m), {}, {
          align: "center"
        });
      });
      var allAggColumns = firstAggCol.concat(numberColumns);
      return allAggColumns;
    },
    aggArr: function aggArr() {
      var _this = this;
      var keysToAgg = this.aggColumns.filter(function (f) {
        return f.value !== "name";
      }).map(function (m) {
        return m.value;
      });
      var keyMap = keysToAgg.reduce(function (acc, k) {
        acc[k] = [];
        return acc;
      }, {});
      var keyToArr = this.results.rows.reduce(function (acc, r) {
        keysToAgg.forEach(function (key) {
          var rawVal = r[key];
          var val = Number(rawVal);
          if (!_this.testNull(val) && rawVal !== "unauth") {
            acc[key].push(val);
          }
        });
        return acc;
      }, keyMap);
      var aggMap = {
        Mean: "mean",
        Median: "median",
        High: "max",
        Low: "min",
        "Standard Deviation": "stdev",
        Count: "count"
      };
      var aggList = this.baseAggList.map(function (baseAgg) {
        var aggFn = _this[aggMap[baseAgg.name]];
        keysToAgg.forEach(function (key) {
          baseAgg[key] = aggFn(keyToArr[key]);
        });
        return baseAgg;
      });
      return aggList;
    }
  },
  methods: {
    mean: function mean() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return _mean(arr);
    },
    median: function median() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return _median(arr);
    },
    max: function max() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return _max(arr);
    },
    min: function min() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return _min(arr);
    },
    stdev: function stdev() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return deviation(arr);
    },
    count: function count() {
      var arr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      return arr.length;
    }
  }
});