import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import debounce from "lodash/debounce";
import useBaseUtils from "~/functions/useBaseUtils";
import useScreenerConditions from "~/functions/useScreenerConditions";
import glossary from "~/utils/templates/glossary";
export default defineComponent({
  props: {
    dataType: {
      type: String,
      "default": null // type: Object,
      // default: () => ({}),
    },
    valueType: {
      type: String,
      "default": null
    },
    periodType: {
      type: String,
      "default": null
    },
    firstPeriodValue: {
      type: [String, Number],
      "default": null
    },
    secondPeriodValue: {
      type: [String, Number],
      "default": null
    },
    periodValue: {
      type: String,
      "default": null
    },
    didType: {
      type: String,
      "default": null
    },
    conditionType: {
      type: String,
      "default": null
    },
    conditionValue: {
      type: [String, Number, Array],
      // FIXME: what is this supposed to be, align w/ validation
      "default": function _default() {
        return [];
      }
    },
    conditionUnit: {
      // type: Object,
      // default: () => ({}),
      type: String,
      "default": "MM USD"
    },
    created: {
      type: String,
      "default": "first"
    },
    screenId: {
      type: String,
      "default": "testid" // TODO: Change this!!!
    },
    arrorder: {
      type: Number,
      "default": 0
    },
    autocompleteFlag: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup(props) {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    var _useScreenerCondition = useScreenerConditions(props),
      dataTypeChoice = _useScreenerCondition.dataTypeChoice,
      valueTypeChoice = _useScreenerCondition.valueTypeChoice,
      periodTypeChoice = _useScreenerCondition.periodTypeChoice,
      firstPeriodValueChoice = _useScreenerCondition.firstPeriodValueChoice,
      secondPeriodValueChoice = _useScreenerCondition.secondPeriodValueChoice,
      periodValueChoice = _useScreenerCondition.periodValueChoice,
      didTypeChoice = _useScreenerCondition.didTypeChoice,
      conditionTypeChoice = _useScreenerCondition.conditionTypeChoice,
      conditionValueChoice = _useScreenerCondition.conditionValueChoice,
      conditionUnitChoice = _useScreenerCondition.conditionUnitChoice,
      conditionChoiceObj = _useScreenerCondition.conditionChoiceObj,
      selectDataTypeItems = _useScreenerCondition.selectDataTypeItems,
      valueTypeItems = _useScreenerCondition.valueTypeItems,
      periodTypeItems = _useScreenerCondition.periodTypeItems,
      periodValueItems = _useScreenerCondition.periodValueItems,
      didTypeItems = _useScreenerCondition.didTypeItems,
      valueTypeMap = _useScreenerCondition.valueTypeMap,
      periodTypeMap = _useScreenerCondition.periodTypeMap,
      didTypeObj = _useScreenerCondition.didTypeObj,
      periodValueMap = _useScreenerCondition.periodValueMap,
      estMultiplePeriodValueMap = _useScreenerCondition.estMultiplePeriodValueMap,
      unitOptions = _useScreenerCondition.unitOptions,
      availableEstPeriodTypes = _useScreenerCondition.availableEstPeriodTypes,
      conditionComplete = _useScreenerCondition.conditionComplete,
      conditionItems = _useScreenerCondition.conditionItems,
      regionItems = _useScreenerCondition.regionItems,
      industryItems = _useScreenerCondition.industryItems,
      countryItems = _useScreenerCondition.countryItems,
      exchangeItems = _useScreenerCondition.exchangeItems,
      sectorItems = _useScreenerCondition.sectorItems,
      baseCategorySet = _useScreenerCondition.baseCategorySet,
      baseCategoryMap = _useScreenerCondition.baseCategoryMap,
      baseFinancialsSet = _useScreenerCondition.baseFinancialsSet,
      baseFinancialsMap = _useScreenerCondition.baseFinancialsMap;
    var lazySearchText = ref(null);
    var searchText = ref(null); // text associated with outer autocomplete
    var dialogSearchText = ref(null); // text associated with dialog
    var dialogDisplayText = ref(null); // text associated with dialog
    var dialogFocused = ref(false);
    var saveSearchText = ref(null);
    var setLazySearchText = function setLazySearchText(newText) {
      lazySearchText.value = newText;
    };
    var debouncesetLazySearchText = debounce(setLazySearchText, 150);
    var showDataTypeDialog = ref(false);
    return {
      glossary: glossary,
      dev: dev,
      refCode: refCode,
      I18nFn: I18nFn,
      dataTypeChoice: dataTypeChoice,
      valueTypeChoice: valueTypeChoice,
      didTypeChoice: didTypeChoice,
      periodTypeChoice: periodTypeChoice,
      firstPeriodValueChoice: firstPeriodValueChoice,
      secondPeriodValueChoice: secondPeriodValueChoice,
      periodValueChoice: periodValueChoice,
      conditionTypeChoice: conditionTypeChoice,
      conditionValueChoice: conditionValueChoice,
      conditionUnitChoice: conditionUnitChoice,
      conditionChoiceObj: conditionChoiceObj,
      selectDataTypeItems: selectDataTypeItems,
      valueTypeItems: valueTypeItems,
      periodTypeItems: periodTypeItems,
      periodValueItems: periodValueItems,
      didTypeItems: didTypeItems,
      unitOptions: unitOptions,
      availableEstPeriodTypes: availableEstPeriodTypes,
      valueTypeMap: valueTypeMap,
      periodTypeMap: periodTypeMap,
      periodValueMap: periodValueMap,
      estMultiplePeriodValueMap: estMultiplePeriodValueMap,
      didTypeObj: didTypeObj,
      conditionComplete: conditionComplete,
      lazySearchText: lazySearchText,
      searchText: searchText,
      dialogSearchText: dialogSearchText,
      dialogDisplayText: dialogDisplayText,
      saveSearchText: saveSearchText,
      debouncesetLazySearchText: debouncesetLazySearchText,
      dialogFocused: dialogFocused,
      showDataTypeDialog: showDataTypeDialog,
      conditionItems: conditionItems,
      regionItems: regionItems,
      industryItems: industryItems,
      countryItems: countryItems,
      exchangeItems: exchangeItems,
      sectorItems: sectorItems,
      baseCategorySet: baseCategorySet,
      baseCategoryMap: baseCategoryMap,
      baseFinancialsSet: baseFinancialsSet,
      baseFinancialsMap: baseFinancialsMap
    };
  },
  data: function data() {
    return {
      conditionTypeToggle: false,
      valueTypeToggle: false,
      periodTypeToggle: false,
      firstPeriodValueToggle: false,
      secondPeriodValueToggle: false,
      didTypeToggle: false
    };
  },
  computed: {
    reversedPeriodValueItems: function reversedPeriodValueItems() {
      // ah, reverse changes in place
      var values = this.periodValueItems.map(function (m) {
        return m;
      });
      return values.reverse() || [];
    },
    selectDataTypeItemsFiltered: function selectDataTypeItemsFiltered() {
      var _this = this;
      var searchText = this.lazySearchText;
      var typeChoice = this.dataTypeChoice;
      if (typeof searchText === "string" && searchText.length > 0) {
        var queryFiltered = this.selectDataTypeItems.filter(function (f) {
          if (typeChoice !== null && typeChoice !== void 0 && typeChoice.value) {
            return typeChoice.value === f.value || _this.vuetifyFilter(f, searchText);
          } else {
            return _this.vuetifyFilter(f, searchText);
          }
        });
        var headersRemoved = queryFiltered.filter(function (f, i, arr) {
          if (arr.length - 1 === i) {
            if (f.header) {
              return false;
            }
          } else {
            var next = arr[i + 1];
            if (next.header && f.header) {
              return false;
            }
          }
          return true;
        });
        return headersRemoved;
      }
      return this.selectDataTypeItems;
    },
    dataTypeSearchError: function dataTypeSearchError() {
      if (this.selectDataTypeItemsFiltered.filter(function (f) {
        return !f.header;
      }).length <= 0) {
        // TODO: translate this phrase
        return "".concat(this.I18nFn("No Data Types match the search query"), ": ").concat(this.lazySearchText);
      }
      return null;
    },
    baseCategories: function baseCategories() {
      return Array.from(this.baseCategorySet);
    },
    filteredItemsByCategory: function filteredItemsByCategory() {
      var _this2 = this;
      var splitFinancials = true;
      var baseMap = this.baseCategories.reduce(function (acc, c) {
        acc[c] = [];
        return acc;
      }, {});
      return this.selectDataTypeItemsFiltered.reduce(function (acc, item) {
        if (!item.header) {
          // don't include headers in this boi
          var catKey = _this2.baseCategoryMap[item.selType];
          if (acc[catKey]) {
            acc[catKey].push(item);
          }
          if (splitFinancials && item.finType) {
            var finKey = _this2.baseFinancialsMap[item.finType];
            if (acc[finKey]) {
              acc[finKey].push(item);
            }
          }
        }
        return acc;
      }, baseMap);
    },
    // financialsCategories() {
    //   return Array.from(this.baseFinancialsSet)
    // },
    // filteredFinancialsItems() {
    //   const baseMap = this.financialsCategories.reduce((acc, c) => {
    //     acc[c] = []
    //     return acc
    //   }, {})
    //   const financialsKey = "Financials"
    //   const financialsItems = this.filteredItemsByCategory[financialsKey]
    //   return financialsItems.reduce((acc, item) => {
    //     if (!item.header) {
    //       // don't include headers in this boi
    //       const finKey = this.baseFinancialsMap[item.finType]
    //       if (acc[finKey]) {
    //         acc[finKey].push(item)
    //       }
    //     }
    //     return acc
    //   }, baseMap)
    // },
    dialogHint: function dialogHint() {
      // TODO: translate me
      if (this.lazySearchText) {
        return "".concat(this.I18nFn("Data matching search"), ": ").concat(this.lazySearchText);
      } else {
        return "";
      }
    }
  },
  watch: {
    conditionChoiceObj: function conditionChoiceObj(newObj) {
      this.updateCondition(newObj);
    },
    conditionComplete: function conditionComplete(newValue) {
      if (newValue) {// FIXME: But what about if the user is changing the conditionValue & wants to see
        // the new results... that wont trigger conditionComplete going from false to true
        // and thus this watcher
      }
    },
    // searchText(newValue, oldValue) {
    //   console.log(`searchText changes from ${oldValue} to ${newValue}`)
    // },
    // dialogSearchText(newValue, oldValue) {
    //   console.log(`dialogSearchText changes from ${oldValue} to ${newValue}`)
    // },
    showDataTypeDialog: function showDataTypeDialog(newValue) {
      if (newValue === true) {
        // this.dialogSearchText = this.saveSearchText
        this.dialogSearchText = "";
      }
      if (newValue === false) {
        this.searchTextUpdate("");
      }
    }
  },
  methods: {
    resetAllExceptDataType: function resetAllExceptDataType() {
      this.valueTypeChoice = null;
      this.periodTypeChoice = null;
      this.firstPeriodValueChoice = null;
      this.secondPeriodValueChioce = null;
      this.didTypeChoice = null;
      this.conditionTypeChoice = null;
      this.conditionValueChoice = null;
    },
    dataTypeSelected: function dataTypeSelected(dataType) {
      if (dataType !== null && dataType !== void 0 && dataType.value) {
        try {
          this.$refs.dataTypeSelect.blur(); // blur from autocomplete component
        } catch (error) {// no emptyblock lol
        } // set searchText to null
        this.searchText = null;
        this.saveSearchText = null;
        this.lazySearchText = null;
        this.showDataTypeDialog = false; // create list of available valueTypeItems for chosen dataType
        if (dataType) {
          if (this.dataTypeChoice && (this.dataTypeChoice.selType !== dataType.selType || this.dataTypeChoice.type !== dataType.type)) {
            this.resetAllExceptDataType();
          }
          this.dataTypeChoice = dataType;
          if (dataType.valueTypeArr && dataType.selType === "est_lineitem") {
            var _this$valueTypeChoice; // check if a valueTypeChoice Exists
            if ((_this$valueTypeChoice = this.valueTypeChoice) !== null && _this$valueTypeChoice !== void 0 && _this$valueTypeChoice.value && this.valueTypeChoice.value !== "v") {
              // a value type has already been selected, open the periodType
              // TODO: if periodTypeChoice exists open firstPeriodValue?
              var validValueTypes = this.valueTypeItems.map(function (v) {
                return v.value;
              });
              if (!validValueTypes.includes(this.valueTypeChoice.value)) {
                this.valueTypeChoice = null;
              } // this.openMenu("periodType")
            } else {
              // no value type has been selected, progress user to valueType select
              this.openMenu("valueType");
            }
          } else if (dataType.selType === "company_attr") {
            var _this$dataTypeChoice;
            var doNotResetPeriodType = ["pricechange"];
            this.periodTypeChoice = null;
            this.firstPeriodValueChoice = null;
            this.secondPeriodValueChoice = null;
            this.didTypeChoice = null;
            if (doNotResetPeriodType.includes((_this$dataTypeChoice = this.dataTypeChoice) === null || _this$dataTypeChoice === void 0 ? void 0 : _this$dataTypeChoice.value)) {
              this.openMenu("periodType");
            } else {
              this.openMenu("conditionType");
            } // the selected event has no valueTypeArr thus is a company attribute
            // if it is a company attribute what are we doing?
            // going to the condition select probably
            // this.resetAllExceptDataType()
          } else if (dataType.selType === "est_multiple") {
            // this.resetAllExceptDataType()
            this.valueTypeChoice = {
              text: "Value",
              value: "v",
              hint: "Value of lineitem"
            };
            this.validateFirstPeriodChoice();
            this.openMenu("periodType");
          } else if (dataType.selType === "hist_multiple") {
            // this.resetAllExceptDataType()
            this.valueTypeChoice = {
              text: "Value",
              value: "v",
              hint: "Value of lineitem"
            };
            this.didTypeChoice = {
              text: "Actual",
              abbr: "Actual",
              value: "actual"
            };
            this.openMenu("periodType");
          } else if (dataType.selType === "fin_lineitem") {
            this.didTypeChoice = {
              text: "Actual",
              abbr: "Actual",
              value: "actual"
            };
            this.validateFirstPeriodChoice();
            this.openMenu("valueType");
          }
        }
      }
    },
    valueTypeSelected: function valueTypeSelected(valueType) {
      this.valueTypeChoice = valueType; // this.$refs[`${prevRef}List`].blur() // I don't know if a blur is needed w/ a menu
      // set the appropriate items for periodType
      // TODO: there should be a way here to handle estimates vs. financials
      if (valueType.value !== "cagr") {
        // remove the secondPeriodValueChoice
        this.secondPeriodValueChoice = null;
      } else {
        // it is a CAGR
        this.didTypeChoice = this.didTypeObj.hist[0];
      }
      this.validateFirstPeriodChoice(); // set the appropriate units for input
      if (valueType.value === "yoyChange") {
        this.yoyChangePeriodTypeSelected();
      }
      if (!this.periodTypeChoice) {
        this.openMenu("periodType");
      }
    },
    validateFirstPeriodChoice: function validateFirstPeriodChoice() {
      // invalidate this.periodTypeChoice if not contained in periodTypeItems
      var validPeriodTypes = this.periodTypeItems.map(function (m) {
        return m.value;
      });
      if (this.periodTypeChoice && !validPeriodTypes.includes(this.periodTypeChoice.value)) {
        this.periodTypeChoice = null;
      }
      if (this.firstPeriodValueChoice) {
        this.validateDidTypeChoice(this.firstPeriodValueChoice);
      }
    },
    periodTypeSelected: function periodTypeSelected(periodType) {
      var _this3 = this;
      this.periodTypeChoice = periodType;
      if (this.firstPeriodValueChoice) {
        var samePeriod = this.periodValueItems.filter(function (f) {
          return f.value === _this3.firstPeriodValueChoice.value && f.periodtype === _this3.firstPeriodValueChoice.periodtype;
        });
        if (samePeriod.length === 1) {
          this.firstPeriodValueChoice = samePeriod[0];
        } else {
          // reset the firstPeriodValueChoice because it is no
          // longer an option for the selected periodType
          // FIXME: something weird is going on here
          this.firstPeriodValueChoice = null; // this.didTypeChoice = null
          if (this.periodValueItems.length === 1) {
            this.firstPeriodValueChoice = this.periodValueItems[0];
          }
        }
      }
      if (periodType.value === "ntm" || periodType.value === "ltm" || this.dataTypeChoice && this.dataTypeChoice.selType === "hist_multiple") {
        // special case, skip the selection of the periodValue
        // zero out the periods..?
        this.firstPeriodValueChoice = this.periodValueItems[0];
        this.validateDidTypeChoice();
        this.conditionTypeChoice ? this.focusOnRef("conditionValueInput") : this.openMenu("conditionType");
      } else if (this.firstPeriodValueChoice) {
        this.validateDidTypeChoice();
        this.openMenu("conditionType");
      } else {
        this.openMenu("firstPeriodValue");
      }
    },
    yoyChangePeriodTypeSelected: function yoyChangePeriodTypeSelected() {
      // yoyChange valueTypeChoice selected
      // if a periodValue has already been selected verify it
      // the periodValue is contained within the new periodValueItems set
      var validPeriodValues = this.periodValueItems.map(function (m) {
        return m.value;
      });
      var firstPeriod = this.firstPeriodValueChoice;
      if (firstPeriod && !validPeriodValues.includes(firstPeriod.value)) {
        this.firstPeriodValueChoice = null;
      }
    },
    periodValueSelected: function periodValueSelected(periodKey, selected) {
      this[periodKey] = selected;
      if (this.dataTypeChoice.selType === "est_multiple") {
        this.validateDidTypeChoice();
        this.conditionTypeChoice ? this.focusOnRef("conditionValueInput") : this.openMenu("conditionType");
      } else if (this.valueTypeChoice.value !== "cagr") {
        // not cagr, set
        this.validateDidTypeChoice();
        this.conditionTypeChoice ? this.focusOnRef("conditionValueInput") : this.openMenu("conditionType");
      } else {
        // it is a cagr...  set didTypeChoice to actual
        this.didTypeChoice = this.didTypeObj.hist[0];
        if (periodKey === "firstPeriodValueChoice") {
          this.openMenu("secondPeriodValue");
        } else {
          this.openMenu("conditionType");
        }
      }
    },
    validateDidTypeChoice: function validateDidTypeChoice() {
      var validDidTypeValues = this.didTypeItems.map(function (m) {
        return m.value;
      });
      if (this.didTypeChoice && !validDidTypeValues.includes(this.didTypeChoice.value)) {
        this.didTypeChoice = this.didTypeItems[0];
      }
      if (!this.didTypeChoice) {
        this.didTypeChoice = this.didTypeItems[0];
      }
    },
    didTypeSelected: function didTypeSelected(didType, prevType) {
      this.didTypeChoice = didType;
      if (prevType) {// TODO: may need logic to change the units on the select here
        // depending on what the next didType.value is
        // and depending on what the valueType is
      }
      if (didType.value === "numEst") {// change the units to integer
      } // focusOn the input unless it should be a select I guess
      if (this.conditionTypeChoice) {
        this.focusOnRef("conditionValueInput");
      } else {
        this.openMenu("conditionType");
      }
    },
    singleConditionTypeSelected: function singleConditionTypeSelected(conditionType) {
      this.conditionTypeChoice = conditionType;
      this.focusOnRef("conditionValueInput");
    },
    focusOnRef: function focusOnRef(nextRef) {
      var _this4 = this;
      this.$nextTick(function () {
        try {
          _this4.$refs[nextRef].focus();
        } catch (error) {
          if (_this4.dev) {
            console.error("Error focusing on ref ".concat(nextRef, " in ScreenerConditionSelect: "), error);
          }
        }
      });
    },
    openMenu: function openMenu(nextRef) {
      var _this5 = this;
      this.$nextTick(function () {
        try {
          _this5["".concat(nextRef, "Toggle")] = true; // this.$nextTick(() => {
          //   try {
          //   } catch (error) {
          //     if (this.dev) {
          //       console.error(
          //         `inner error openMenu ${nextRef} in ScreenerConditionSelect: `,
          //         error
          //       )
          //     }
          //   }
          // })
        } catch (error) {
          if (_this5.dev) {
            console.error("Error openingMenu on ref ".concat(nextRef, " in ScreenerConditionSelect: "), error);
          }
        }
      });
    },
    deleteCondition: function deleteCondition() {
      this.$store.dispatch("screener/deleteScreenerCondition", {
        id: this.screenId,
        cond: this.conditionChoiceObj
      });
    },
    updateCondition: function updateCondition(newObj) {
      this.$store.dispatch("screener/updateScreenerCondition", {
        id: this.screenId,
        cond: newObj
      });
    },
    vuetifyFilter: function vuetifyFilter(item, queryText) {
      if (item.header) {
        return true;
      }
      var itemText = item.text;
      var itemAbbr = item.abbr;
      var itemKeys = item.keywords;
      var inItemText = itemText.toLocaleLowerCase().includes(queryText.toLocaleLowerCase());
      var inItemAbbr = itemAbbr ? itemAbbr.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) : false;
      var inItemKeys = itemKeys ? itemKeys.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) : false; // const itemGroup = item.dataTypeGroup
      // let inItemGroup = false
      // if (itemGroup) {
      //   inItemGroup = itemGroup
      //     ? itemGroup
      //         .toLocaleLowerCase()
      //         .indexOf(queryText.toLocaleLowerCase()) > -1
      //     : false
      // }
      // return inItemText || inItemAbbr || inItemGroup
      return inItemText || inItemAbbr || inItemKeys;
    },
    toggleDataTypeDialog: function toggleDataTypeDialog() {
      var bool = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDataTypeDialog = bool;
    },
    dataTypeInputFocus: function dataTypeInputFocus() {
      var _this$dataTypeChoice2;
      if ((_this$dataTypeChoice2 = this.dataTypeChoice) !== null && _this$dataTypeChoice2 !== void 0 && _this$dataTypeChoice2.value) {// user has already selected a value...
        // this.searchText = this.saveSearchText
      } else {
        this.searchText = this.saveSearchText;
      }
    },
    dialogDataTypeInputFocus: function dialogDataTypeInputFocus() {
      // this.dialogSearchText = ""
      this.dialogFocused = true;
      this.dialogSearchText = this.saveSearchText;
    },
    searchTextUpdate: function searchTextUpdate(text) {
      // I think this function is how I can intercept update of searchText
      // console.log("searchTextUpdate: ", text)
      if (text === null || typeof text !== "string") {
        // search has blur'd, ignore and don't reset anything
        this.searchText = "";
      } else {
        // user has input something, save the text &
        // set the new lazySearchValue
        this.saveSearchText = text;
        this.debouncesetLazySearchText(text);
      }
    },
    dialogSearchTextUpdate: function dialogSearchTextUpdate(text) {
      // I think this function is how I can intercept update of searchText
      // console.log("dialogSearchTextUpdate: ", text)
      if (text === null || typeof text !== "string") {// search has blur'd, ignore and don't reset anything
        // this.searchText = null
      } else {
        // user has input something, save the text &
        // set the new lazySearchValue
        this.saveSearchText = text;
        this.debouncesetLazySearchText(text);
      }
    },
    resetLazySearch: function resetLazySearch(ref) {
      this.$refs[ref].lazySearch = "";
    }
  }
});