import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { allScreenerItems, companyAttributes, countryArr, estimateLineitems, exchangeArr, financialsLineitems, forwardMultiplesItems, industryArr, priceChangeTimePeriods, regionArr, screenerItemAccumulator, sectorArr, trailingMultiplesItems, unitOptions, valueTypeMetaObj } from "~/components/screener/screener_lineitems";
import { hasProp, isConditionComplete, isConditionValueValid, translateDataTypeObj } from "~/utils/screener";
var stage = process.env.LAMBDA_STAGE;
var dev = stage === "dev";
var useScreenerConditionUtils = function useScreenerConditionUtils(props) {
  var _useNuxtApp = useNuxtApp(),
    $Amplify = _useNuxtApp.$Amplify; // I feel like wrapping this in a function could lead
  // to some weird javascript scope problems..?
  var I18nFn = function I18nFn(text) {
    return $Amplify.I18n.get(text);
  };
  var translatedPriceChangePeriods = priceChangeTimePeriods.map(function (m) {
    if (m.text) {
      m.text = I18nFn(m.text);
    }
    if (m.abbr) {
      m.abbr = I18nFn(m.text);
    }
    return m;
  });
  var screenItemMap = allScreenerItems.reduce(function (returnAcc, itemObj) {
    return screenerItemAccumulator(returnAcc, itemObj, I18nFn);
  }, {});
  var conditionsObj = {
    value: [
    // { text: "greater than", value: "gt" },
    // { text: "greater than or equal to", value: "gte" },
    // { text: "less than", value: "lt" },
    // { text: "less than or equal to", value: "lte" },
    {
      text: I18nFn("greater than (>)"),
      abbr: ">",
      value: "gt"
    }, {
      text: I18nFn("greater than or equal to (>=)"),
      abbr: ">=",
      value: "gte"
    }, {
      text: I18nFn("less than (<)"),
      abbr: "<",
      value: "lt"
    }, {
      text: I18nFn("less than or equal to (<=)"),
      abbr: "<=",
      value: "lte"
    },
    // { text: "=", value: "eq" },
    {
      text: I18nFn("display only"),
      abbr: "display",
      value: "do"
    }],
    singleItem: [{
      text: I18nFn("is"),
      value: "is"
    }, {
      text: I18nFn("is not"),
      value: "isnot"
    }, {
      text: I18nFn("display only"),
      abbr: "display",
      value: "do"
    }],
    groupItem: [{
      text: I18nFn("is one of"),
      value: "isgroup"
    }, {
      text: I18nFn("is not one of"),
      value: "isnotgroup"
    }, {
      text: I18nFn("display only"),
      abbr: "display",
      value: "do"
    }]
  };
  var periodTypes = [{
    fullText: I18nFn("Fiscal Year"),
    abbr: I18nFn("FY"),
    range: [-5, 3]
  }, {
    fullText: I18nFn("Calendar Year"),
    abbr: I18nFn("CY"),
    range: [-5, 3]
  }, {
    fullText: I18nFn("Fiscal Quarter"),
    abbr: I18nFn("FQ"),
    range: [-8, 5]
  }];
  var quarterEstimatePeriods = [{
    text: "+5",
    value: 5,
    periodtype: "est"
  }, {
    text: "+4",
    value: 4,
    periodtype: "est"
  }, {
    text: "+3",
    value: 3,
    periodtype: "est"
  }, {
    text: "+2",
    value: 2,
    periodtype: "est"
  }, {
    text: "+1",
    value: 1,
    periodtype: "est"
  }, {
    text: "0",
    value: 0,
    periodtype: "histest"
  }, {
    text: "-1",
    value: -1,
    periodtype: "histest"
  }, {
    text: "-2",
    value: -2,
    periodtype: "hist"
  }, {
    text: "-3",
    value: -3,
    periodtype: "hist"
  }, {
    text: "-4",
    value: -4,
    periodtype: "hist"
  }, {
    text: "-5",
    value: -5,
    periodtype: "hist"
  }, {
    text: "-6",
    value: -6,
    periodtype: "hist"
  }, {
    text: "-7",
    value: -7,
    periodtype: "hist"
  }, {
    text: "-8",
    value: -8,
    periodtype: "hist"
  }];
  var annualEstimatePeriods = [{
    text: "+5",
    value: 5,
    periodtype: "est"
  }, {
    text: "+4",
    value: 4,
    periodtype: "est"
  }, {
    text: "+3",
    value: 3,
    periodtype: "est"
  }, {
    text: "+2",
    value: 2,
    periodtype: "est"
  }, {
    text: "+1",
    value: 1,
    periodtype: "est"
  }, {
    text: "0",
    value: 0,
    periodtype: "histest"
  }, {
    text: "-1",
    value: -1,
    periodtype: "histest"
  }, {
    text: "-2",
    value: -2,
    periodtype: "hist"
  }, {
    text: "-3",
    value: -3,
    periodtype: "hist"
  }, {
    text: "-4",
    value: -4,
    periodtype: "hist"
  }, {
    text: "-5",
    value: -5,
    periodtype: "hist"
  }, {
    text: "-10",
    value: -10,
    periodtype: "hist"
  }, {
    text: "-15",
    value: -15,
    periodtype: "hist"
  }];
  var annualFinancialPeriods = [{
    text: "2024",
    value: 2024,
    periodtype: "hist"
  }, {
    text: "2023",
    value: 2023,
    periodtype: "hist"
  }, {
    text: "2022",
    value: 2022,
    periodtype: "hist"
  }, {
    text: "2021",
    value: 2021,
    periodtype: "hist"
  }, {
    text: "2020",
    value: 2020,
    periodtype: "hist"
  }, {
    text: "2019",
    value: 2019,
    periodtype: "hist"
  }, {
    text: "2018",
    value: 2018,
    periodtype: "hist"
  }, {
    text: "2017",
    value: 2017,
    periodtype: "hist"
  }, {
    text: "2016",
    value: 2016,
    periodtype: "hist"
  }, {
    text: "2015",
    value: 2015,
    periodtype: "hist"
  }, {
    text: "2014",
    value: 2014,
    periodtype: "hist"
  }, {
    text: "2013",
    value: 2013,
    periodtype: "hist"
  }, {
    text: "2012",
    value: 2012,
    periodtype: "hist"
  }, {
    text: "2011",
    value: 2011,
    periodtype: "hist"
  }, {
    text: "2010",
    value: 2010,
    periodtype: "hist"
  }, {
    text: "2009",
    value: 2009,
    periodtype: "hist"
  }, {
    text: "2008",
    value: 2008,
    periodtype: "hist"
  }, {
    text: "2007",
    value: 2007,
    periodtype: "hist"
  }, {
    text: "2006",
    value: 2006,
    periodtype: "hist"
  } // { text: "2005", value: 2005, periodtype: "hist" },
  ];
  var quarterFinancialPeriods = [];
  var didTypeObj = {
    hist: [{
      text: I18nFn("Actual"),
      abbr: I18nFn("Actual"),
      value: "actual"
    }, {
      text: I18nFn("Management Guidance Mid"),
      abbr: I18nFn("Mgmt Mid"),
      value: "guideMid"
    }, {
      text: I18nFn("Management Guidance High"),
      abbr: I18nFn("Mgmt High"),
      value: "guideHigh"
    }, {
      text: I18nFn("Management Guidance Low"),
      abbr: I18nFn("Mgmt Low"),
      value: "guideLow"
    }],
    histest: [{
      text: I18nFn("Actual"),
      abbr: I18nFn("Actual"),
      value: "actual"
    }, {
      text: I18nFn("Mean Consensus Estimate"),
      abbr: I18nFn("Mean"),
      value: "mean"
    }, {
      text: I18nFn("Median Consensus Estimate"),
      abbr: I18nFn("Median"),
      value: "median"
    }, {
      text: I18nFn("High Consensus Estimate"),
      abbr: I18nFn("High"),
      value: "high"
    }, {
      text: I18nFn("Low Consensus Estimate"),
      abbr: I18nFn("Low"),
      value: "low"
    }, {
      text: I18nFn("Number of Consensus Estimates"),
      abbr: I18nFn("# Est"),
      value: "numEst"
    }, {
      text: I18nFn("Consensus Estimate Standard Deviation"),
      abbr: I18nFn("Mean StDev"),
      value: "stdDev"
    }, {
      text: I18nFn("Management Guidance Mid"),
      abbr: I18nFn("Mgmt Mid"),
      value: "guideMid"
    }, {
      text: I18nFn("Management Guidance High"),
      abbr: I18nFn("Mgmt High"),
      value: "guideHigh"
    }, {
      text: I18nFn("Management Guidance Low"),
      abbr: I18nFn("Mgmt Low"),
      value: "guideLow"
    }],
    est: [{
      text: I18nFn("Mean Consensus Estimate"),
      abbr: I18nFn("Mean"),
      value: "mean"
    }, {
      text: I18nFn("Median Consensus Estimate"),
      abbr: I18nFn("Median"),
      value: "median"
    }, {
      text: I18nFn("High Consensus Estimate"),
      abbr: I18nFn("High"),
      value: "high"
    }, {
      text: I18nFn("Low Consensus Estimate"),
      abbr: I18nFn("Low"),
      value: "low"
    }, {
      text: I18nFn("Number of Consensus Estimates"),
      abbr: I18nFn("# Est"),
      value: "numEst"
    }, {
      text: I18nFn("Consensus Estimate Standard Deviation"),
      abbr: I18nFn("Mean StDev"),
      value: "stdDev"
    }, {
      text: I18nFn("Management Guidance Mid"),
      abbr: I18nFn("Mgmt Mid"),
      value: "guideMid"
    }, {
      text: I18nFn("Management Guidance High"),
      abbr: I18nFn("Mgmt High"),
      value: "guideHigh"
    }, {
      text: I18nFn("Management Guidance Low"),
      abbr: I18nFn("Mgmt Low"),
      value: "guideLow"
    }],
    fin: [{
      text: I18nFn("Actual"),
      abbr: I18nFn("Actual"),
      value: 0
    }]
  };
  var valueTypeMap = Object.keys(valueTypeMetaObj).reduce(function (acc, k) {
    var metaObj = valueTypeMetaObj[k];
    acc[k] = _objectSpread(_objectSpread({}, metaObj), {}, {
      value: k,
      text: I18nFn(metaObj.text),
      hint: I18nFn(metaObj.hint)
    });
    return acc;
  }, {});
  var availableEstPeriodTypes = [{
    text: I18nFn("Fiscal Year"),
    abbr: I18nFn("FY"),
    value: "fy"
  }, {
    text: I18nFn("Calendar Year"),
    abbr: I18nFn("CY"),
    value: "cy"
  }, {
    text: I18nFn("Next Twelve Months"),
    abbr: I18nFn("NTM"),
    value: "ntm"
  }, {
    text: I18nFn("Fiscal Quarter"),
    abbr: I18nFn("FQ"),
    value: "fq"
  }];
  var availableFinPeriodTypes = [{
    text: I18nFn("Fiscal Year"),
    abbr: I18nFn("FY"),
    value: "annual"
  },
  // { text: I18nFn("Fiscal Quarter"), abbr: I18nFn("FQ"), value: "quarter" },
  {
    text: I18nFn("Last Twelve Months"),
    abbr: I18nFn("LTM"),
    value: "ltm"
  }];
  var allAvailablePeriodsTypes = availableFinPeriodTypes.concat(availableEstPeriodTypes, translatedPriceChangePeriods);
  var periodTypeMap = allAvailablePeriodsTypes.reduce(function (acc, pObj) {
    acc[pObj.value] = pObj;
    return acc;
  }, {});
  var periodValueMap = {
    fy: annualEstimatePeriods,
    cy: annualEstimatePeriods,
    fq: quarterEstimatePeriods,
    ntm: [{
      text: "0",
      value: 0,
      periodtype: "est"
    }]
  };
  var finPeriodValueMap = {
    annual: annualFinancialPeriods,
    quarter: quarterFinancialPeriods,
    ltm: [{
      text: "0",
      value: 0,
      periodtype: "hist"
    }]
  };
  var estMultiplePeriodValueMap = {
    fy: annualEstimatePeriods.filter(function (f) {
      return f.value > 0;
    }),
    cy: annualEstimatePeriods.filter(function (f) {
      return f.value > 0;
    }),
    fq: quarterEstimatePeriods.filter(function (f) {
      return f.value > 0;
    }),
    ntm: [{
      text: "0",
      value: 0,
      periodtype: "est"
    }] // TODO: add "afy", "acy" keys for absolute fiscal and calendar year...
    // the backend is now setup for it...
  }; // TODO: THIS IS GOING TO BE AN ISSUE, hard coding this in...
  var finMultiplePeriodValueMap = {
    annual: [{
      text: "2021",
      value: 2021,
      periodtype: "hist"
    }],
    ltm: [{
      text: "2021",
      value: 2021,
      periodtype: "hist"
    }]
  }; /**
     *
     * dataTypeChoice, valueTypeChoice, periodTypeChoice Below:
     *
     */
  var dataTypeChoice = ref(props.dataType ? screenItemMap[props.dataType] : null);
  var valueTypeChoice = ref(props.valueType ? valueTypeMap[props.valueType] : null);
  var periodTypeChoice = ref(props.periodType ? periodTypeMap[props.periodType] : null); /**
                                                                                         *
                                                                                         * first and second PeriodValueChoice in addition to
                                                                                         * available items and associated periodMap below
                                                                                         *
                                                                                         */ // available periodValueItems user can select from is a computed property
  // based on other selections... in fact all of the *Items array are
  // actually computed properties. If I can get this logic right (which is complicated)
  // then the rest of the methods in the component will be more straight forward
  var periodValueItems = computed(function () {
    var periodItems = [];
    try {
      var _dataTypeChoice$value; // check if user has selected a dataTypeChoice
      if (dataTypeChoice !== null && dataTypeChoice !== void 0 && (_dataTypeChoice$value = dataTypeChoice.value) !== null && _dataTypeChoice$value !== void 0 && _dataTypeChoice$value.selType) {
        var _periodTypeChoice$val, _valueTypeChoice$valu; // check if the user has selected a periodTypeChoice which
        // dictates the whole available space of periods...
        if ((_periodTypeChoice$val = periodTypeChoice.value) !== null && _periodTypeChoice$val !== void 0 && _periodTypeChoice$val.value) {
          var selType = dataTypeChoice.value.selType;
          var pValue = periodTypeChoice.value.value;
          if (selType === "est_multiple") {
            periodItems = estMultiplePeriodValueMap[pValue];
          } else if (selType === "est_lineitem") {
            periodItems = periodValueMap[pValue];
          } else if (selType === "hist_multiple") {
            periodItems = finMultiplePeriodValueMap[pValue];
          } else if (selType === "fin_lineitem") {
            periodItems = finPeriodValueMap[pValue];
            return periodItems;
          }
        } // check if the user has selected (or is changing) the valueTypeChoice
        // not all periods are available for each valueTypeChoice
        if ((_valueTypeChoice$valu = valueTypeChoice.value) !== null && _valueTypeChoice$valu !== void 0 && _valueTypeChoice$valu.value && valueTypeChoice.value.value === "yoyChange") {
          // if the valueTypeChoice is yoyChange then then only display period.value > -5
          periodItems = periodItems.filter(function (f) {
            return f.value >= -5;
          });
        }
      }
    } catch (error) {
      var showError = false;
      if (dev && showError) {
        console.error("error creating computed property periodValueItems in useScreenerConditions: ", error);
      }
    }
    return periodItems;
  });
  var allPeriodValueMap = computed(function () {
    return periodValueItems.value.reduce(function (acc, pObj) {
      acc[pObj.value] = pObj;
      return acc;
    }, {});
  });
  var firstPeriodValueChoice = ref(hasProp(props, "firstPeriodValue") ? allPeriodValueMap.value[props.firstPeriodValue] : null);
  var secondPeriodValueChoice = ref(hasProp(props, "secondPeriodValue") ? allPeriodValueMap.value[props.secondPeriodValue] : null);
  var periodValueChoice = computed(function () {
    var returnArr = [];
    if (hasProp(firstPeriodValueChoice.value, "value")) {
      var _firstPeriodValueChoi;
      returnArr.push((_firstPeriodValueChoi = firstPeriodValueChoice.value) === null || _firstPeriodValueChoi === void 0 ? void 0 : _firstPeriodValueChoi.value);
    }
    if (hasProp(secondPeriodValueChoice.value, "value")) {
      var _secondPeriodValueCho; // returnArr.push(null) // why are you pushin null here?
      returnArr.push((_secondPeriodValueCho = secondPeriodValueChoice.value) === null || _secondPeriodValueCho === void 0 ? void 0 : _secondPeriodValueCho.value);
    }
    return returnArr;
  }); /**
      *
      * didTypeChoice below, with available didTypeItems and
      * associated didTypeMap
      *
      */
  var didTypeItems = computed(function () {
    var didItems = [];
    try {
      var _firstPeriodValueChoi2;
      if ((_firstPeriodValueChoi2 = firstPeriodValueChoice.value) !== null && _firstPeriodValueChoi2 !== void 0 && _firstPeriodValueChoi2.periodtype) {
        var _dataTypeChoice$value2;
        didItems = didTypeObj[firstPeriodValueChoice.value.periodtype];
        if ((dataTypeChoice === null || dataTypeChoice === void 0 || (_dataTypeChoice$value2 = dataTypeChoice.value) === null || _dataTypeChoice$value2 === void 0 ? void 0 : _dataTypeChoice$value2.selType) === "est_multiple") {
          // remove numEst and stdDev if dataTypeChoice is a est_multiple
          var toRemove = ["numEst", "stdDev"];
          didItems = didItems.filter(function (f) {
            return !toRemove.includes(f.value);
          });
        }
      }
    } catch (error) {
      if (dev) {
        console.error("error creating computed property didTypeItems in useScreenerConditions: ", error);
      }
    }
    return didItems;
  });
  var didTypeMap = computed(function () {
    return didTypeItems.value.reduce(function (acc, dObj) {
      acc[dObj.value] = dObj;
      return acc;
    }, {});
  });
  var didTypeChoice = ref(props.didType ? didTypeMap.value[props.didType] : null); /**
                                                                                   *
                                                                                   * conditionTypeChoice with conditionItems and conditionType map below
                                                                                   * also conditionValueChoice below and associated
                                                                                   * conditionUnitChoice computed property
                                                                                   *
                                                                                   */
  var conditionItems = computed(function () {
    var items = conditionsObj.value;
    try {
      var _dataTypeChoice$value3;
      if (dataTypeChoice !== null && dataTypeChoice !== void 0 && (_dataTypeChoice$value3 = dataTypeChoice.value) !== null && _dataTypeChoice$value3 !== void 0 && _dataTypeChoice$value3.type) {
        var dataType = dataTypeChoice.value.type;
        var numericTypes = ["float", "int", "floatint", "turns", "pct", "ogprodrate", "ogprod"];
        if (!numericTypes.includes(dataType)) {
          // item is not a numeric type so need to figure out
          // what conditions are available for this item
          items = conditionsObj.groupItem;
        }
      }
    } catch (error) {
      if (dev) {
        console.error("error determing condition items in useScreenerConditions: ", error);
      }
    }
    return items;
  });
  var conditionTypeMap = computed(function () {
    return conditionItems.value.reduce(function (acc, cObj) {
      acc[cObj.value] = cObj;
      return acc;
    }, {});
  });
  var conditionTypeChoice = ref(props.conditionType ? conditionTypeMap.value[props.conditionType] : null);
  var conditionValueChoice = ref(props.conditionValue ? props.conditionValue : null);
  var conditionUnitChoice = computed(function () {
    var unitStr = "MM USD";
    try {
      var _dataTypeChoice$value4, _dataTypeChoice$value5, _dataTypeChoice$value6; // if this is wrapped in a try catch... do I need to worry
      // about property access if I am seeing the computed with
      // a return value that is returned outside of the trycatch?
      var selType = dataTypeChoice === null || dataTypeChoice === void 0 || (_dataTypeChoice$value4 = dataTypeChoice.value) === null || _dataTypeChoice$value4 === void 0 ? void 0 : _dataTypeChoice$value4.selType;
      var unitType = dataTypeChoice === null || dataTypeChoice === void 0 || (_dataTypeChoice$value5 = dataTypeChoice.value) === null || _dataTypeChoice$value5 === void 0 ? void 0 : _dataTypeChoice$value5.type;
      unitStr = unitOptions[unitType];
      if ((_dataTypeChoice$value6 = dataTypeChoice.value) !== null && _dataTypeChoice$value6 !== void 0 && _dataTypeChoice$value6.unitStr) {
        var _dataTypeChoice$value7;
        unitStr = dataTypeChoice === null || dataTypeChoice === void 0 || (_dataTypeChoice$value7 = dataTypeChoice.value) === null || _dataTypeChoice$value7 === void 0 ? void 0 : _dataTypeChoice$value7.unitStr; // does this need to be I18n'd
      }
      if (valueTypeChoice.value && hasProp(valueTypeChoice.value, "value")) {
        var valueType = valueTypeChoice.value.value;
        var pctTypes = ["yoyChange", "margins", "cagr"];
        if (pctTypes.includes(valueType)) {
          unitStr = unitOptions.pct; // make sure pct is the correct key for unit options
        }
      }
      if (didTypeChoice.value && hasProp(didTypeChoice.value, "value") && didTypeChoice.value.value === "numEst") {
        unitStr = "";
      }
      if (selType === "company_attr") {
        var dataTypeUnitStr = dataTypeChoice.value.unitStr;
        if (dataTypeUnitStr) {
          unitStr = dataTypeUnitStr;
        }
      }
    } catch (error) {
      if (dev) {
        console.error("captured error creating computed property conditionUnitChoice in useScreenerConditions: ", error);
      }
    }
    return unitStr;
  }); // TODO: whole computed property of the ScreenerConditionData below
  var conditionChoiceObj = computed(function () {
    var _dataTypeChoice$value8, _valueTypeChoice$valu2, _periodTypeChoice$val2, _firstPeriodValueChoi3, _secondPeriodValueCho2, _periodValueChoice$va, _didTypeChoice$value, _conditionTypeChoice$;
    return {
      dataType: dataTypeChoice === null || dataTypeChoice === void 0 || (_dataTypeChoice$value8 = dataTypeChoice.value) === null || _dataTypeChoice$value8 === void 0 ? void 0 : _dataTypeChoice$value8.value,
      valueType: (_valueTypeChoice$valu2 = valueTypeChoice.value) === null || _valueTypeChoice$valu2 === void 0 ? void 0 : _valueTypeChoice$valu2.value,
      periodType: (_periodTypeChoice$val2 = periodTypeChoice.value) === null || _periodTypeChoice$val2 === void 0 ? void 0 : _periodTypeChoice$val2.value,
      firstPeriodValue: (_firstPeriodValueChoi3 = firstPeriodValueChoice.value) === null || _firstPeriodValueChoi3 === void 0 ? void 0 : _firstPeriodValueChoi3.value,
      secondPeriodValue: (_secondPeriodValueCho2 = secondPeriodValueChoice.value) === null || _secondPeriodValueCho2 === void 0 ? void 0 : _secondPeriodValueCho2.value,
      periodValue: (_periodValueChoice$va = periodValueChoice.value) === null || _periodValueChoice$va === void 0 ? void 0 : _periodValueChoice$va.value,
      didType: (_didTypeChoice$value = didTypeChoice.value) === null || _didTypeChoice$value === void 0 ? void 0 : _didTypeChoice$value.value,
      conditionType: (_conditionTypeChoice$ = conditionTypeChoice.value) === null || _conditionTypeChoice$ === void 0 ? void 0 : _conditionTypeChoice$.value,
      conditionValue: conditionValueChoice.value,
      conditionUnit: conditionUnitChoice.value,
      created: props.created
    };
  }); // if true the condition is complete and can be submitted
  var conditionComplete = computed(function () {
    var _dataTypeChoice$value9, _valueTypeChoice$valu3, _periodTypeChoice$val3, _firstPeriodValueChoi4, _secondPeriodValueCho3, _didTypeChoice$value2, _conditionTypeChoice$2; // move ref's to variables to make the rest of the code a bit more managable
    // remember "value" is the property of a vue-ref holding the 'reactive data'
    // also then I can take this function to other places and specify these
    // variables as input from an object
    var dataType = (_dataTypeChoice$value9 = dataTypeChoice.value) === null || _dataTypeChoice$value9 === void 0 ? void 0 : _dataTypeChoice$value9.value;
    var valueType = (_valueTypeChoice$valu3 = valueTypeChoice.value) === null || _valueTypeChoice$valu3 === void 0 ? void 0 : _valueTypeChoice$valu3.value;
    var periodType = (_periodTypeChoice$val3 = periodTypeChoice.value) === null || _periodTypeChoice$val3 === void 0 ? void 0 : _periodTypeChoice$val3.value;
    var firstPeriodValue = (_firstPeriodValueChoi4 = firstPeriodValueChoice.value) === null || _firstPeriodValueChoi4 === void 0 ? void 0 : _firstPeriodValueChoi4.value;
    var secondPeriodValue = (_secondPeriodValueCho3 = secondPeriodValueChoice.value) === null || _secondPeriodValueCho3 === void 0 ? void 0 : _secondPeriodValueCho3.value;
    var didType = (_didTypeChoice$value2 = didTypeChoice.value) === null || _didTypeChoice$value2 === void 0 ? void 0 : _didTypeChoice$value2.value;
    var conditionType = (_conditionTypeChoice$2 = conditionTypeChoice.value) === null || _conditionTypeChoice$2 === void 0 ? void 0 : _conditionTypeChoice$2.value;
    var conditionValue = conditionValueChoice.value;
    var conditionUnit = conditionUnitChoice.value;
    return isConditionComplete({
      dataType: dataType,
      valueType: valueType,
      periodType: periodType,
      firstPeriodValue: firstPeriodValue,
      secondPeriodValue: secondPeriodValue,
      didType: didType,
      conditionType: conditionType,
      conditionValue: conditionValue,
      conditionUnit: conditionUnit
    });
  });
  var valueTypeItems = computed(function () {
    var valueItems = [];
    try {
      var _dataTypeChoice$value10; // const finSelType = ["fin_lineitem"]
      if ((_dataTypeChoice$value10 = dataTypeChoice.value) !== null && _dataTypeChoice$value10 !== void 0 && _dataTypeChoice$value10.valueTypeArr) {
        // build the available value type choices here
        valueItems = dataTypeChoice.value.valueTypeArr.map(function (m) {
          return valueTypeMap[m];
        });
      } // else if (
      //   dataTypeChoice.value &&
      //   finSelType.includes(dataTypeChoice.value.selType)
      // ) {
      //   const items = ["v", "yoyChange", "cagr"]
      //   valueItems = items.map((m) => {
      //     return valueTypeMap[m]
      //   })
      // }
    } catch (error) {
      if (dev) {
        console.error("error creating computed property valueTypeItems in useScreenerConditions: ", error);
      }
    }
    return valueItems;
  }); // available periodTypes to select from
  var periodTypeItems = computed(function () {
    var periodItems = [];
    var onlyCyFy = ["cagr", "yoyChange"];
    var noQuarters = ["est_multiple"];
    try {
      var _valueTypeChoice$valu4; // what is periodTypeItems dependent on - the valueTypechoice
      // FIXME: check dataTypeChoice?
      var financialsSelTypes = ["fin_lineitem", "hist_multiple"];
      var priceChangeValues = ["pricechange"];
      if (dataTypeChoice.value && priceChangeValues.includes(dataTypeChoice.value.value)) {
        periodItems = translatedPriceChangePeriods;
      } else if (dataTypeChoice.value && financialsSelTypes.includes(dataTypeChoice.value.selType)) {
        periodItems = availableFinPeriodTypes.filter(function (f) {
          if (dataTypeChoice.value.selType === "hist_multiple" && f.value === "quarter") {
            return false;
          }
          if (valueTypeChoice.value && onlyCyFy.includes(valueTypeChoice.value.value) && f.value === "ltm") {
            return false;
          }
          if (dataTypeChoice.value.periodTypeArr && dataTypeChoice.value.periodTypeArr.length > 0 && !dataTypeChoice.value.periodTypeArr.includes(f.value)) {
            return false;
          }
          return f;
        });
      } else if ((_valueTypeChoice$valu4 = valueTypeChoice.value) !== null && _valueTypeChoice$valu4 !== void 0 && _valueTypeChoice$valu4.value) {
        var typeChoice = valueTypeChoice.value.value;
        periodItems = availableEstPeriodTypes;
        if (onlyCyFy.includes(typeChoice)) {
          // remove NTM from period types
          periodItems = availableEstPeriodTypes.filter(function (f) {
            return f.value === "cy" || f.value === "fy";
          });
        } // if it is a forward multiple remove fiscal quarter
        if (dataTypeChoice.value && noQuarters.includes(dataTypeChoice.value.selType)) {
          periodItems = availableEstPeriodTypes.filter(function (f) {
            return f.value !== "fq";
          });
        }
      }
    } catch (error) {
      var showError = false;
      if (dev && showError) {// console.error(
        //   "error creating computed property periodTypeItems in useScreenerConditions: ",
        //   error
        // )
      }
    }
    return periodItems;
  });
  var beginEstimateItems = [{
    text: "Consensus Estimate Line Item",
    header: "Consensus Estimate Line Item"
  }];
  var beginCompanyItems = [{
    text: "Company Attributes",
    header: "Company Attributes"
  }];
  var beginForwardMultiplesItems = [{
    text: "Forward Multiples",
    header: "Forward Multiples"
  }];
  var beginTrailingMultiplesItems = [{
    text: "Trailing Multiples",
    header: "Trailing Multiples"
  }];
  var beginFinancialsItems = [{
    text: "Historical Financials Line Item",
    header: "Historical Financials Line Item"
  }]; // baseCategoryMap defines the vertical tabs in the screenerCondition dialog
  var baseCategoryMap = {
    company_attr: "Company",
    hist_multiple: "Valuation",
    est_multiple: "Valuation",
    // fin_lineitem: "Financials",
    is: "Income Statement",
    bs: "Balance Sheet",
    cf: "Cash Flow",
    r: "Ratios",
    est_lineitem: "Estimates"
  };
  var baseCategorySet = new Set();
  Object.values(baseCategoryMap).forEach(function (v) {
    return baseCategorySet.add(v);
  });
  var baseFinancialsMap = {
    is: "Income Statement",
    bs: "Balance Sheet",
    cf: "Cash Flow",
    r: "Ratios"
  };
  var baseFinancialsSet = new Set();
  Object.values(baseFinancialsMap).forEach(function (v) {
    return baseFinancialsSet.add(v);
  });
  var companyAttributeItems = beginCompanyItems.concat(companyAttributes.filter(function (f) {
    return f.dataTypeGroup && f.screener;
  })); // build array of dataTypes (revenue, ebit, marketcap) to choose from
  var selectDataTypeItems = [];
  selectDataTypeItems = selectDataTypeItems.concat(companyAttributeItems);
  selectDataTypeItems = selectDataTypeItems.concat(beginForwardMultiplesItems.concat(forwardMultiplesItems));
  selectDataTypeItems = selectDataTypeItems.concat(beginTrailingMultiplesItems.concat(trailingMultiplesItems));
  selectDataTypeItems = selectDataTypeItems.concat(beginFinancialsItems.concat(financialsLineitems));
  selectDataTypeItems = selectDataTypeItems.concat(beginEstimateItems.concat(estimateLineitems));
  var count = financialsLineitems;
  var total = count.map(function (m) {
    var pc = m.periodTypeCount;
    var annual = Number(pc.annual) || 0;
    var ltm = Number(pc.ltm) || 0;
    var quarter = Number(pc.quarter) || 0;
    var total = annual + ltm + quarter;
    return _objectSpread(_objectSpread({}, m), {}, {
      total: total
    });
  });
  total.sort(function (a, b) {
    return a.total > b.total;
  });
  selectDataTypeItems = selectDataTypeItems.map(function (i) {
    return translateDataTypeObj(i, I18nFn);
  });
  var regionItems = regionArr.map(function (m) {
    return translateDataTypeObj(m, I18nFn);
  }).sort(function (a, b) {
    return a.text.localeCompare(b.text);
  });
  var industryItems = industryArr.map(function (m) {
    return translateDataTypeObj(m, I18nFn);
  }).sort(function (a, b) {
    return a.text.localeCompare(b.text);
  });
  var countryItems = countryArr.map(function (m) {
    return translateDataTypeObj(m, I18nFn);
  }).sort(function (a, b) {
    return a.text.localeCompare(b.text);
  });
  var exchangeItems = exchangeArr.map(function (m) {
    return translateDataTypeObj(m, I18nFn);
  });
  var sectorItems = sectorArr.map(function (m) {
    return translateDataTypeObj(m, I18nFn);
  }).sort(function (a, b) {
    return a.text.localeCompare(b.text);
  });
  return {
    dataTypeChoice: dataTypeChoice,
    valueTypeChoice: valueTypeChoice,
    periodTypeChoice: periodTypeChoice,
    firstPeriodValueChoice: firstPeriodValueChoice,
    secondPeriodValueChoice: secondPeriodValueChoice,
    periodValueChoice: periodValueChoice,
    didTypeChoice: didTypeChoice,
    conditionTypeChoice: conditionTypeChoice,
    conditionValueChoice: conditionValueChoice,
    conditionUnitChoice: conditionUnitChoice,
    conditionChoiceObj: conditionChoiceObj,
    periodTypes: periodTypes,
    selectDataTypeItems: selectDataTypeItems,
    valueTypeItems: valueTypeItems,
    periodTypeItems: periodTypeItems,
    periodValueItems: periodValueItems,
    didTypeObj: didTypeObj,
    valueTypeMap: valueTypeMap,
    didTypeItems: didTypeItems,
    periodTypeMap: periodTypeMap,
    periodValueMap: periodValueMap,
    estMultiplePeriodValueMap: estMultiplePeriodValueMap,
    unitOptions: unitOptions,
    availableEstPeriodTypes: availableEstPeriodTypes,
    conditionComplete: conditionComplete,
    isConditionValueValid: isConditionValueValid,
    conditionsObj: conditionsObj,
    conditionItems: conditionItems,
    regionItems: regionItems,
    industryItems: industryItems,
    countryItems: countryItems,
    exchangeItems: exchangeItems,
    sectorItems: sectorItems,
    baseCategorySet: baseCategorySet,
    baseCategoryMap: baseCategoryMap,
    baseFinancialsMap: baseFinancialsMap,
    baseFinancialsSet: baseFinancialsSet
  };
};
export default useScreenerConditionUtils;