import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  props: {
    type: {
      type: String,
      "default": "scatter"
    },
    title: {
      type: String,
      "default": "Histogram"
    },
    columnvalue: {
      type: Array,
      "default": function _default() {
        return ["usdmarketcap"];
      }
    },
    rows: {
      type: Array,
      "default": function _default() {
        return [];
      }
    }
  },
  setup: function setup(props) {
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var testNull = function testNull(val) {
      return _typeof(val) === "object" && !val;
    };
    var data = computed(function () {
      var valArr = props.columnvalue;
      var rows = props.rows;
      if (valArr.length === 1) {
        return rows.map(function (r) {
          return Number(r[valArr[0]]);
        }).filter(function (f) {
          return !testNull(f);
        });
      } else {
        return rows.map(function (r) {
          return valArr.map(function (val) {
            return Number(r[val.value]);
          });
        }).filter(function (f) {
          var anyNull = false;
          f.forEach(function (val) {
            if (testNull(val)) {
              anyNull = true;
            }
          });
          return !anyNull;
        });
      }
    });
    var getChartDesc = function getChartDesc(props) {
      var isHistogram = props.type === "histogram";
      if (isHistogram) {
        return props.title;
      } else {
        var columns = Object.keys(props.columnvalue).map(function (key) {
          return props.columnvalue[key].text;
        }, []); // en-GB has no oxford comma at the end
        var list = new Intl.ListFormat("en-GB");
        return list.format(columns);
      }
    };
    var chartOptions = computed(function () {
      var chartType = props.type;
      var colArr = props.columnvalue;
      var chartDescription = getChartDesc(props);
      var scatter = {
        title: {
          text: "scatter" // `${props.title} Histogram`,
        },
        chart: {
          type: "scatter",
          zooming: {
            mouseWheel: false
          } // zoomType: "xy",
        },
        xAxis: {
          title: {
            text: colArr[0].text
          }
        },
        yAxis: {
          title: {
            text: colArr[0].text
          }
        },
        series: [{
          data: data.value
        }],
        accessibility: {
          description: chartDescription
        }
      };
      var histogram = {
        title: {
          text: "".concat(props.title)
        },
        xAxis: [{
          title: {
            text: "".concat(props.title),
            alignTicks: false
          }
        }],
        yAxis: [{
          title: {
            text: I18nFn("# Occurances")
          }
        }],
        plotOptions: {// histogram: {
          //   accessibility: {
          //     pointDescriptionFormatter: function (point) {
          //       var ix = point.index + 1,
          //         x1 = point.x.toFixed(3),
          //         x2 = point.x2.toFixed(3),
          //         val = point.y
          //       return ix + ". " + x1 + " to " + x2 + ", " + val + "."
          //     },
          //   },
          // },
        },
        series: [{
          name: "Histogram",
          type: "histogram",
          // binsNumber: 100,
          binsNumber: "square-root",
          // default
          // binsNumber: "sturges", // default
          // binsNumber: "rice", // default
          // xAxis: 1,
          // yAxis: 1,
          baseSeries: "s1" // this is unique to highcharts
          // zIndex: -1
        }, {
          // name: "Data",
          // type: "scatter",
          data: data.value,
          id: "s1",
          visible: false // marker: {
          //   radius: 1.5,
          // },
        }],
        accessibility: {
          description: chartDescription
        }
      };
      if (chartType === "histogram") {
        return histogram;
      } else if (chartType === "scatter") {
        return scatter;
      } else {
        return scatter;
      }
    });
    return {
      chartOptions: chartOptions,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      fullscreen: false
    };
  },
  created: function created() {
    var _this = this; // this could be replaced with useFullscreen() from "vueuse"
    document.addEventListener("fullscreenchange", function () {
      _this.fullscreen = Boolean(document.fullscreenElement);
    });
  }
});