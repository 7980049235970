var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"footer-props":_vm.footerProps,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"height":_vm.tableHeight,"fixed-header":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item, headers }){return [_c('tr',_vm._l((headers),function(header){return _c('td',{key:header.value,staticClass:"text-center"},[(header.columntype === 'ts')?[(item.companyid === 'unauth')?_c('ScreenerUnauthTblCell'):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('nuxt-link',{class:{
                  'accent--text': _vm.$vuetify.theme.dark,
                },attrs:{"to":`/stock/about?cid=${item.companyid}&tid=${item.tradingitemid}&ref=${_vm.refCode}`}},[_c('b',_vm._g({},on),[_vm._v("\n                  "+_vm._s(item.ticker)+"\n                ")])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(item.companyname))])])]:(header.columntype === 'name')?[(item.companyid === 'unauth')?_c('ScreenerUnauthTblCell'):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('nuxt-link',_vm._g({class:{
                  'accent--text': _vm.$vuetify.theme.dark,
                },attrs:{"to":`/stock/about?cid=${item.companyid}&tid=${item.tradingitemid}&ref=${_vm.refCode}`}},on),[_vm._v("\n                "+_vm._s(item.companyname)+"\n              ")])]}}],null,true)})]:(header.columntype === 'fav')?[(item.companyid === 'unauth')?_c('ScreenerUnauthTblCell'):_c('FavButton',{attrs:{"ticker":item,"small":true}})]:(header.columntype === 'pct')?[(
              !_vm.testNull(item[header.value]) && item[header.value] === 'unauth'
            )?_c('ScreenerUnauthTblCell'):_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.displayPercentTwoDecimals(item[header.value]))+"\n          ")])]:(header.columntype === 'whole_pct')?[(
              !_vm.testNull(item[header.value]) && item[header.value] === 'unauth'
            )?_c('ScreenerUnauthTblCell'):_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.displayPercentTwoDecimals(item[header.value] / 100))+"\n          ")])]:(header.columntype === 'turns')?[(
              !_vm.testNull(item[header.value]) && item[header.value] === 'unauth'
            )?_c('ScreenerUnauthTblCell'):_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.formatTurnsValue(item[header.value], _vm.showTurns))+"\n          ")])]:(header.columntype === 'mc')?[(
              !_vm.testNull(item[header.value]) && item[header.value] === 'unauth'
            )?_c('ScreenerUnauthTblCell'):_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.formatMarketCapValue(item[header.value], _vm.showTurns))+"\n          ")])]:(header.columntype === 'float')?[(
              !_vm.testNull(item[header.value]) && item[header.value] === 'unauth'
            )?_c('ScreenerUnauthTblCell'):_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.displayFloat(item[header.value]))+"\n          ")])]:(header.columntype === 'int')?[(
              !_vm.testNull(item[header.value]) && item[header.value] === 'unauth'
            )?_c('ScreenerUnauthTblCell'):_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.displayInt(item[header.value]))+"\n          ")])]:(header.columntype === 'industry')?[_vm._v("\n          "+_vm._s(_vm.testNull(item[header.value])
              ? "-"
              : _vm.displayMapValue(_vm.industryMap, item[header.value]))+"\n        ")]:(header.columntype === 'sector')?[_vm._v("\n          "+_vm._s(_vm.testNull(item[header.value])
              ? "-"
              : _vm.displayMapValue(_vm.industryIdToSector, item[header.value]))+"\n        ")]:(header.columntype === 'country')?[_vm._v("\n          "+_vm._s(_vm.testNull(item[header.value])
              ? "-"
              : _vm.displayMapValue(_vm.countryMap, item[header.value]))+"\n        ")]:[(
              !_vm.testNull(item[header.value]) && item[header.value] === 'unauth'
            )?_c('ScreenerUnauthTblCell'):[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value]) ? "-" : item[header.value])+"\n          ")]]],2)}),0)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }