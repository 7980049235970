var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-data-table',{attrs:{"headers":_vm.aggColumns,"items":_vm.aggArr,"dense":_vm.dense,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function({ item, headers }){return [_c('tr',_vm._l((headers),function(header){return _c('td',{key:header.value,staticClass:"text-center"},[(header.value === 'name')?[_c('b',[_vm._v("\n            "+_vm._s(_vm.I18nFn(item.name))+"\n          ")])]:(item.name === 'Count')?[_vm._v("\n          "+_vm._s(item[header.value])+"\n        ")]:(header.columntype === 'pct')?[_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.displayPercentTwoDecimals(item[header.value]))+"\n          ")])]:(header.columntype === 'whole_pct')?[_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.displayPercentTwoDecimals(item[header.value] / 100))+"\n          ")])]:(header.columntype === 'turns')?[_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.formatTurnsValue(item[header.value], _vm.showTurns))+"\n          ")])]:(header.columntype === 'mc')?[_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.formatMarketCapValue(item[header.value], _vm.showTurns))+"\n          ")])]:(header.columntype === 'float')?[_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.displayFloat(item[header.value]))+"\n          ")])]:(header.columntype === 'int')?[_c('span',{class:_vm.displayRedWhenValNegative(item[header.value])},[_vm._v("\n            "+_vm._s(_vm.testNull(item[header.value])
                ? "-"
                : _vm.displayInt(item[header.value]))+"\n          ")])]:[_vm._v("\n          "+_vm._s(_vm.testNull(item[header.value]) ? "-" : item[header.value])+"\n        ")]],2)}),0)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }