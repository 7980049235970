import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
import ScreenerUnauthTblCell from "./ScreenerUnauthTblCell.vue";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useBaseUtils from "~/functions/useBaseUtils";
import { countryArr, industryArr, industryIdToSector, screenerItemAccumulator } from "~/components/screener/screener_lineitems";
import useWindow from "~/functions/useWindow";
import useToFormatWatchlist from "~/functions/useToFormatWatchlist";
export default defineComponent({
  components: {
    ScreenerUnauthTblCell: ScreenerUnauthTblCell
  },
  props: {
    results: {
      type: Object,
      "default": function _default() {
        return {
          rows: [],
          fields: []
        };
      }
    },
    columns: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    screenerId: {
      type: String,
      "default": "testid"
    },
    showTurns: {
      type: Boolean,
      "default": true
    }
  },
  setup: function setup(props) {
    var _useNuxtApp = useNuxtApp(),
      $Amplify = _useNuxtApp.$Amplify;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode;
    var _useWindow = useWindow(props, "ScreenerResultsTable"),
      tableHeight = _useWindow.tableHeight;
    var _useNumberFormatters = useNumberFormatters(),
      displayPercentTwoDecimals = _useNumberFormatters.displayPercentTwoDecimals,
      formatMarketCapValue = _useNumberFormatters.formatMarketCapValue,
      formatTurnsValue = _useNumberFormatters.formatTurnsValue,
      displayFloat = _useNumberFormatters.displayFloat,
      displayInt = _useNumberFormatters.displayInt;
    var _useToFormatWatchlist = useToFormatWatchlist(),
      displayRedWhenValNegative = _useToFormatWatchlist.displayRedWhenValNegative;
    var testNull = function testNull(val) {
      return _typeof(val) === "object" && !val;
    };
    var footerProps = {
      // itemsPerPageOptions: [5, 15, 50, 100, 200, 500, 1000 - 1],
      itemsPerPageOptions: [10, 25, 50, 100, 500] // itemsPerPageOptions: [10, 50, 100, 500],
    };
    var I18nFn = function I18nFn(text) {
      return $Amplify.I18n.get(text);
    };
    var industryMap = industryArr.reduce(function (returnAcc, itemObj) {
      return screenerItemAccumulator(returnAcc, itemObj, I18nFn);
    }, {});
    var countryMap = countryArr.reduce(function (returnAcc, itemObj) {
      return screenerItemAccumulator(returnAcc, itemObj, I18nFn);
    }, {});
    var displayMapValue = function displayMapValue(map, mapKey) {
      try {
        var returnTxt = mapKey;
        var mapObj = map[mapKey];
        if (mapObj.abbr) {
          returnTxt = mapObj.abbr;
        } else if (mapObj.text) {
          returnTxt = mapObj.text;
        } else if (typeof mapObj === "string") {
          // this is kind of hacky
          returnTxt = I18nFn(mapObj);
        }
        return returnTxt;
      } catch (_unused) {}
    };
    return {
      footerProps: footerProps,
      displayPercentTwoDecimals: displayPercentTwoDecimals,
      refCode: refCode,
      formatMarketCapValue: formatMarketCapValue,
      formatTurnsValue: formatTurnsValue,
      displayFloat: displayFloat,
      displayInt: displayInt,
      displayRedWhenValNegative: displayRedWhenValNegative,
      industryMap: industryMap,
      countryMap: countryMap,
      displayMapValue: displayMapValue,
      testNull: testNull,
      tableHeight: tableHeight,
      industryIdToSector: industryIdToSector
    };
  },
  computed: {
    sortBy: {
      get: function get() {
        var dataKey = "sortBy";
        var storeObj = this.$store.state.screener.screenDataObj[this.screenerId];
        var dataObj = storeObj[dataKey];
        return dataObj || ["usdmarketcap"];
      },
      set: function set(value) {
        var dataKey = "sortBy";
        this.$store.dispatch("screener/updateScreenerMetaData", {
          screenId: this.screenerId,
          dataKey: dataKey,
          value: value
        });
      }
    },
    sortDesc: {
      get: function get() {
        var dataKey = "sortDesc";
        var storeObj = this.$store.state.screener.screenDataObj[this.screenerId];
        var dataObj = storeObj[dataKey];
        return dataObj || [true];
      },
      set: function set(value) {
        var dataKey = "sortDesc";
        this.$store.dispatch("screener/updateScreenerMetaData", {
          screenId: this.screenerId,
          dataKey: dataKey,
          value: value
        });
      }
    },
    itemsPerPage: {
      get: function get() {
        var dataKey = "itemsPerPage";
        var storeObj = this.$store.state.screener.screenDataObj[this.screenerId];
        var dataObj = storeObj[dataKey];
        return dataObj || 25;
      },
      set: function set(value) {
        var dataKey = "itemsPerPage";
        this.$store.dispatch("screener/updateScreenerMetaData", {
          screenId: this.screenerId,
          dataKey: dataKey,
          value: value
        });
      }
    },
    page: {
      get: function get() {
        var dataKey = "page";
        var storeObj = this.$store.state.screener.screenDataObj[this.screenerId];
        var dataObj = storeObj[dataKey];
        return dataObj || 1;
      },
      set: function set(value) {
        var dataKey = "page";
        this.$store.dispatch("screener/updateScreenerMetaData", {
          screenId: this.screenerId,
          dataKey: dataKey,
          value: value
        });
      }
    },
    items: function items() {
      // const fields = this.results.fields.map((f) => f.name)
      // return this.results.rows.map((r) => {
      //   const returnObj = {}
      //   fields.forEach((f, idx) => {
      //     returnObj[f] = r[idx]
      //   })
      //   return returnObj
      // })
      return this.results.rows;
    }
  }
});