import { isConditionComplete } from "~/utils/screener"

const transformScreenerConditions = () => {
  const completeConditionToUniqueStr = (conditionObj) => {
    /**
            {
              dataType,
              valueType,
              periodType,
              firstPeriodValue,
              secondPeriodValue,
              didType,
              conditionType,
              conditionValue,
              created
            }
         */

    const returnStr = conditionObj.dataType
    let returnArr = []
    let conditionBoolean = conditionObj.conditionBoolean
      ? conditionObj.conditionBoolean
      : "AND"
    // not sure where you were planning on adding the conditionObj.conditionBool
    // hook... so the code below may be a bit useless
    if (conditionObj.conditionType && conditionObj.conditionType === "do") {
      conditionBoolean = "OR"
    }
    const delimiter = "_"
    const splitDataTypeValue = returnStr.split(delimiter)
    const firstType = splitDataTypeValue[0]
    // TODO: when adding new companyAttributes, the
    const companyAttrTypes = [
      "usdmarketcap",
      "usdtev",
      "usdc",
      "sharesoutstanding",
      // TODO: figure out how to handle region and simpleindustryid
      "region",
      "simpleindustryid",
      "companycountryid",
      "sector",
      "exchangesymbol",
    ]
    // let metaObj = { created: conditionObj.created }
    const validFirstTypes = ["est", "fmulti", "hmulti", "fin"]
    const validPriceFirstTypes = ["price", "pricechange"]

    if (validFirstTypes.includes(firstType)) {
      // it is an estimate attribute
      const didType = conditionObj.didType ? conditionObj.didType : "" // CAGR does not have a didType... freak
      const valueType = conditionObj.valueType
      const periodType = conditionObj.periodType
      const firstPeriodValue = conditionObj.firstPeriodValue
      const secondPeriodValue = conditionObj.secondPeriodValue
      const conditionType = conditionObj.conditionType
      const conditionValue = conditionObj.conditionValue
      const conditionUnit = conditionObj.conditionUnit
      // you are officially over thinking... just make it werk
      // returnStr = `${splitDataTypeValue[0]}_${splitDataTypeValue[1]}_${didType}_${valueType}_${periodType}_${firstPeriodValue}_${secondPeriodValue}_${conditionType}_${conditionValue}`

      returnArr = [
        conditionBoolean,
        splitDataTypeValue[0],
        splitDataTypeValue[1],
        didType,
        valueType,
        periodType,
        firstPeriodValue,
        secondPeriodValue,
        conditionType,
        conditionValue,
        conditionUnit,
      ]
    } else if (validPriceFirstTypes.includes(firstType)) {
      // it is a company price request
      const periodType = conditionObj.periodType
      const conditionType = conditionObj.conditionType
      const conditionValue = conditionObj.conditionValue
      const conditionUnit = conditionObj.conditionUnit

      // returnStr = `company_${splitDataTypeValue[0]}_${conditionType}_${conditionValue}`
      returnArr = [
        conditionBoolean,
        splitDataTypeValue[0],
        // splitDataTypeValue[1],
        periodType,
        conditionType,
        conditionValue,
        conditionUnit,
      ]
    } else if (companyAttrTypes.includes(firstType)) {
      // it is a company attribute
      const conditionType = conditionObj.conditionType
      let conditionValue = conditionObj.conditionValue
      const conditionUnit = conditionObj.conditionUnit
      if (conditionValue && typeof conditionValue === "object") {
        // conditionValue is an array
        conditionValue = conditionValue.map((m) => m.value).join("##")
      }

      // returnStr = `company_${splitDataTypeValue[0]}_${conditionType}_${conditionValue}`
      returnArr = [
        conditionBoolean,
        "company",
        splitDataTypeValue[0],
        conditionType,
        conditionValue,
        conditionUnit,
      ]
      // this is enough information to determine the database & attribute of interest... right?
    }
    // else if (selType === "fmulti") {
    //   // TODO:
    // }
    // let returnObj = {}
    // returnObj[conditionObj.created] = returnArr.join(delimiter)
    // return returnObj
    // return [metaObj, returnArr.join(delimiter)]
    return [conditionObj.created, returnArr.join(delimiter)]
  }

  const condArrToCompleteArr = (condArr = []) => {
    const completeConditions = condArr
      .filter((f) => isConditionComplete(f))
      .map(completeConditionToUniqueStr)
    return completeConditions
  }

  return {
    condArrToCompleteArr,
  }
}

export default transformScreenerConditions
